import React, { Component } from 'react';

class PaddedSection extends Component {
  getClassName() {
    switch (this.props.width) {
      case 'wide':
        return 'wide-padded-section';
        break;
      case 'normal':
        return 'normal-padded-section';
        break;
      case 'narrow':
        return 'narrow-padded-section';
        break;
      default:
        return 'normal-padded-section';
    }
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.props.children}
      </div>
    );
  }
}

export default PaddedSection;
