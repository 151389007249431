import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import PaddedSection from '../components/PaddedSection';
import Layout from '../components/layout';
import SEO from '../components/seo';

const BlogPage = ({ data }) => (
  <Layout>
  <div>test</div>
  {/*
    <PaddedSection>
      <br />
      <br />
      <SEO title="PixoPixa" />
      <h1>Latest Posts</h1>
      <hr />
      {data.allMarkdownRemark.edges.map((post) => (
        <div key={post.node.id}>
          <h3>{post.node.frontmatter.title}</h3>
          <small>
            posted by
            {post.node.frontmatter.author}
            {' '}
            on
            {post.node.frontmatter.date}
          </small>
          <br />
          <br />
          <Link to={post.node.frontmatter.path}> Read More ...</Link>
          <br />
          <br />
          <hr />
        </div>
      ))}
      <br />
      <br />
    </PaddedSection>
    */}
  </Layout>
);

// export const pageQuery = graphql`
// query BlogIndexQuery {
//   allMarkdownRemark {
//     edges {
//       node {
//         id
//         frontmatter {
//           path
//           title
//           date
//           author
//         }
//       }
//     }
//   }
// }
// `;

export default BlogPage;
